import breakpoints from "theme/base/breakpoints";

const {
  values: { sm, md, lg, xl, xxl },
} = breakpoints;

export const SM = `@media (min-width: ${sm}px)`;
export const MD = `@media (min-width: ${md}px)`;
export const LG = `@media (min-width: ${lg}px)`;
export const XL = `@media (min-width: ${xl}px)`;
export const XXL = `@media (min-width: ${xxl}px)`;

export const dwSM = `@media (max-width: ${sm}px)`;
export const dwSMMD = `@media (max-width: ${md}px)`;
export const dwSMLG = `@media (max-width: ${lg}px)`;
export const dwSMXL = `@media (max-width: ${xl}px)`;
export const dwSMXXL = `@media (max-width: ${xxl}px)`;

const sharedClasses = {
  paddingRight: `calc(1.5rem * 0.5) !important`,
  paddingLeft: `calc(1.5rem * 0.5) !important`,
  marginRight: "auto !important",
  marginLeft: "auto !important",
  width: "100% !important",
  position: "relative",
};

export default {
  ".MuiContainer-root": {
    ...sharedClasses,
    maxWidth: "540px !important",
  },
  [SM]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "540px !important",
    },
  },
  [MD]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "720px !important",
    },
  },
  [LG]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "960px !important",
    },
  },
  [XL]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "1140px !important",
    },
  },
  [XXL]: {
    ".MuiContainer-root": {
      ...sharedClasses,
      maxWidth: "1320px !important",
    },
  },
  "&.MuiContainer-fixed": {
    maxWidth: "1320px !important",
  }
};
