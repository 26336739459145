import { Container } from "@mui/material";
import Preloader from "common/preloader";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";


const TraineesRoot = () => {
  return (
    <Container>
        <Suspense fallback={<Preloader />}>
            <Outlet />
        </Suspense>
    </Container>
  )
}

export default TraineesRoot;