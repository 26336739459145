import colors from 'theme/base/colors';
import typography from 'theme/base/typography';
import boxShadows from 'theme/base/boxShadows';
import pxToRem from 'theme/functions/pxToRem';
import { LG } from '../container';

const { white, warning, text, primary, dark, info, error, secondary } = colors;
const { size } = typography;
const { buttonBoxShadow } = boxShadows;

const contained = {
  base: {
    backgroundColor: white.main,
    minHeight: pxToRem(40),
    color: text.main,
    boxShadow: 'none',
    padding: `${pxToRem(10)} ${pxToRem(15)}`,

    '&:hover': {
      backgroundColor: white.main,
      boxShadow: "none",
    },

    '&:focus, &:active, &:active:focus, &:active:hover, &:focus:not(:hover), &:disabled': {
      boxShadow: "none !important",
    },

    '& .material-icon, .material-icons-round, svg, .MuiButton-startIcon>*:nth-of-type(1), .MuiButton-endIcon>*:nth-of-type(1)': {
      fontSize: `${pxToRem(15)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(8)} ${pxToRem(12)}`,
    fontSize: size.xs,

    '& .material-icon, .material-icons-round, svg, .MuiButton-startIcon>*:nth-of-type(1), .MuiButton-endIcon>*:nth-of-type(1)': {
      fontSize: `${pxToRem(19)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(45),
    padding: `${pxToRem(12)} ${pxToRem(20)}`,
    fontSize: 15,
    [LG]: {
      fontSize: size.regular,
    },
    '& .material-icon, .material-icons-round, svg, .MuiButton-startIcon>*:nth-of-type(1), .MuiButton-endIcon>*:nth-of-type(1)': {
      fontSize: `${pxToRem(18)} !important`,
    },
  },

  primary: {
    backgroundColor: primary.main,
    color: white.main,

    '&:hover, &:focus': {
      backgroundColor: text.main,
    },
    '&:disabled': {
      backgroundColor: primary.main,
      color: white.main,
    },
    '&:active': {
      backgroundColor: "#0a58ca",
    },
  },

  secondary: {
    backgroundColor: secondary.main,

    '&:hover': {
      backgroundColor: secondary.main,
    },

    '&:focus:not(:hover)': {
      backgroundColor: secondary.focus,
      boxShadow: buttonBoxShadow.stateOfNotHover,
    },
  },

  warning: {
    backgroundColor: warning.focus,
    color: white.main,
    '&:hover, &:focus': {
      backgroundColor: warning.main,
    },
    '&:active, &:disabled': {
      backgroundColor: warning.focus,
      color: white.main,
    },
  },

  info: {
    backgroundColor: info.main,
    color: white.main,
    '&:hover, &:focus': {
      backgroundColor: info.focus,
    },
    '&:active, &:disabled': {
      backgroundColor: info.focus,
      color: white.main,
    },
  },

  error: {
    backgroundColor: error.main,
    color: white.main,
    '&:hover, &:focus': {
      backgroundColor: error.focus,
    },
    '&:active, &:disabled': {
      backgroundColor: error.focus,
      color: white.main,
    },
  },

  dark: {
    backgroundColor: dark.main,
    color: white.main,
    '&:hover, &:focus': {
      backgroundColor: primary.main,
    },
    '&:active, &:disabled': {
      backgroundColor: dark.focus,
      color: white.main,
    },
  },

  light: {
    backgroundColor: white.main,
    color: primary.main,
    '&:hover, &:focus': {
      backgroundColor: dark.main,
      color: white.main,
    },
  },
};
export default contained;
