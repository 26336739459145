
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isOffline: Boolean(!window.navigator.onLine),
  trainingPageLimit: localStorage.getItem("training_page_limit") || 6,
};

export const uiCtrlSlice = createSlice({
  name: "uiCtrlSlice",
  initialState,
  reducers: {
    setIsOffline: (state, action) => {
      state.isOffline = action.payload;
    },
    setTrainingPageLimit: (state, action) => {
      state.trainingPageLimit = action.payload;
      localStorage.setItem("training_page_limit", action.payload)
    }
  },
});

export const { setIsOffline, setTrainingPageLimit } = uiCtrlSlice.actions;

export default uiCtrlSlice.reducer;
