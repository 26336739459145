import { baseAPIService } from ".";

const UserApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    contactUs: build.mutation({
      query: (data) => {
        return {
          url: "user/contactUsQuery",
          method: "POST",
          body: data,
        };
      },
    }),
    userProfile: build.query({
      query: () => {
        return {
          url: "user/profile",
        };
      },
      keepUnusedDataFor: 1200,
      providesTags: ['GET_PROFILE'],
    }),
    userProfileUpdate: build.mutation({
      query: (data) => {
        return {
          url: "user/updateProfile",
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["GET_PROFILE"],
    }),
    userChangePassword: build.mutation({
      query: (data) => {
        return {
          url: "user/changePassword",
          method: "PATCH",
          body: data,
        };
      },
    }),
    userMyBookingCourt: build.query({
      query: (params = {}) => {
        return {
          url: "user/myBooking",
          params: { limit: 5, ...params }
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['GET_BOOKING'],
    }),
    userDashboard: build.query({
      query: (params = {}) => {
        return {
          url: "user/dashboard",
          params: { page: 1, limit: 6, ...params }
        };
      },
      keepUnusedDataFor: 120,
      providesTags: ['GET_BOOKING'],
    }),
    userTrainees: build.query({
      query: (params = {}) => {
        return {
          url: "user/student",
          params: { page: 1, ...params  }
        };
      },
      keepUnusedDataFor: 120,
      providesTags: ['GET_TRAINEE'],
    }),
    userChangeTrainee: build.mutation({
      query: ({data, method}) => {
        return {
          url: "user/student",
          method: method ? "PUT" : "POST",
          body: data,
        };
      },
      invalidatesTags: ["GET_TRAINEE"],
    }),
    userDeleteTrainee: build.mutation({
      query: (id) => {
        return {
          url: `user/student/${id}`,
          method: "DELETE"
        };
      },
      invalidatesTags: ["GET_TRAINEE"],
    })
  }),
  overrideExisting: true,
});
export const {
  useContactUsMutation,
  useUserProfileQuery,
  useUserProfileUpdateMutation,
  useUserChangePasswordMutation,
  useUserMyBookingCourtQuery,
  useUserDashboardQuery,
  useUserTraineesQuery,
  useUserChangeTraineeMutation,
  useUserDeleteTraineeMutation,
} = UserApi;
