import root from 'theme/components/button/root';
import contained from 'theme/components/button/contained';
import outlined from 'theme/components/button/outlined';
import text from 'theme/components/button/text';

const button = {
  styleOverrides: {
    root: { ...root },
    contained: { ...contained.base },
    containedSizeSmall: { ...contained.small },
    containedSizeLarge: { ...contained.large },
    containedPrimary: { ...contained.primary },
    containedSecondary: { ...contained.secondary },
    containedWarning: { ...contained.warning },
    containedInfo: { ...contained.info },
    containedError: { ...contained.error },
    containedDark: { ...contained.dark },
    containedLight: { ...contained.light },
    outlined: { ...outlined.base },
    outlinedSizeSmall: { ...outlined.small },
    outlinedSizeLarge: { ...outlined.large },
    outlinedPrimary: { ...outlined.primary },
    outlinedSecondary: { ...outlined.secondary },
    text: { ...text.base },
    textSizeSmall: { ...text.small },
    textSizeLarge: { ...text.large },
    textPrimary: { ...text.primary },
    textSecondary: { ...text.secondary },
    textInfo: { ...text.info },
    textError: { ...text.error },
    textDark: { ...text.dark },
  },
};
export default button;
